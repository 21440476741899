import React, { useState } from "react"
import { Text } from "grommet"
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from "./form/ContactForm"
import {
  AddressInfoContainer,
  AddressInfoCover,
  AnimationContainer,
  ContactContentWrapper,
  ContactIconCover,
  InfoContent,
  SuccessMessageContainer,
} from "./styles"
import Lottie from "lottie-react"
import successAnimation from "../../data/message-sent-success.json"
import data from "../../assets/data.json"

const ContactUs = () => {
  const [isMessageSent, setisMessageSent] = useState(false)

  return (
    <ContactContentWrapper>
      <AddressInfoContainer>
        <AddressInfoCover>
          <ContactIconCover>
            <StaticImage
              src="../../assets/images/phone-icon.png"
              alt=""
            />
          </ContactIconCover>
          <InfoContent>
            <h3>Phone Number</h3>
            <h4>+65 6455 9938</h4>
          </InfoContent>
        </AddressInfoCover>
        <AddressInfoCover>
          <ContactIconCover>
            <StaticImage
              src="../../assets/images/email-icon.png"
              alt=""
            />
          </ContactIconCover>
          <InfoContent>
            <h3>Email Address</h3>
            <h4>training@aia.edu.sg</h4>
          </InfoContent>
        </AddressInfoCover>
        <AddressInfoCover>
          <ContactIconCover>
            <StaticImage
              src="../../assets/images/location-icon.png"
              alt=""
            />
          </ContactIconCover>
          <InfoContent>
            <h3>Address</h3>
            <h4>{data.address}</h4>
          </InfoContent>
        </AddressInfoCover>
      </AddressInfoContainer>
      {isMessageSent ? (
        <SuccessMessageContainer>
          <AnimationContainer>
            <Lottie
              animationData={successAnimation}
              loop={false}
            />
          </AnimationContainer>
        </SuccessMessageContainer>
      ) : (
        <ContactForm
          setisMessageSent={setisMessageSent}
        />
      )}
    </ContactContentWrapper>
  )
}

export default ContactUs
