import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import NumberFormat from "react-number-format"
import * as queryString from "query-string"
import { API } from "aws-amplify"
import { Row, Col, Container, Table } from "reactstrap"
import Link from "../components/link"
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaFax,
} from "react-icons/fa"
import Styled from "styled-components"
import Hr from "../components/hr"
import PageTitle from "../components/page-title"
import SEO from "../components/seo"
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"
import ContactUs from "../components/contact/ContactUs"

const Cirle = Styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

let StyledRow = Styled(Row)`
  justify-content: center;
`

let ContactItem = ({
  text,
  type,
  Icon,
  href,
  children,
}) => (
  <Col
    xs={6}
    lg={3}
    className="d-flex flex-column align-items-center"
    style={{
      wordBreak: "break-word",
    }}
  >
    <Cirle className="h-4-rem w-4-rem bg-primary-faded my-3 absolute-center rounded-circle">
      <Icon size={30} />
    </Cirle>
    <div className="text-center">
      <h6>{type}</h6>
      <span className="text-secondary">
        {href ? (
          <Link to={href} className="reset">
            {children ? children : text}
          </Link>
        ) : children ? (
          children
        ) : (
          text
        )}
      </span>
    </div>
  </Col>
)

const Contact = ({ data, location }) => {
  const [session, setSession] = useState(null)
  let { address, email, phone, fax } =
    data.site.siteMetadata
  let { session_id } = queryString.parse(location.search)

  return (
    <>
      <SEO
        title="Contact Us | Ace Industrial Academy"
        description="Contact Us | Ace Industrial Academy"
      />

      <TitleBannerEnquiry
        title="Contact Us"
        description="Reach out to us for training dates and course fees"
        withOnlyDescription
        hasPlainBanner
      />

      <ContactUs />
    </>
  )
}

export const query = graphql`
  query Contact {
    site {
      siteMetadata {
        address
        email
        phone
        fax
      }
    }
  }
`

export default Contact
