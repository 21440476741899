import React from "react"
import { API } from "aws-amplify"
import {
  FormContainer,
  FormTitle,
  FormDescription,
  FormField,
} from "./styles"
import { useForm } from "react-hook-form"
import { sesCall } from "../../../graphql/queries"
import MailTo from "../../../context/mailto"
import { StaticImage } from "gatsby-plugin-image"

const ContactForm = (props) => {
  const { setisMessageSent } = props
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    sesNotification(data)
    setisMessageSent(true)
  }

  async function sesNotification(data) {
    const { name, email, phone, message } = data
    try {
      await API.graphql({
        query: sesCall,
        variables: {
          msg: {
            name: name,
            email: email,
            phone: phone,
            course: message,
          },
        },
      })
    } catch (err) {
      console.log("error triggering lambda function: ", err)
    }
  }

  console.log(watch())

  return (
    <FormContainer className="contactForm">
      <FormTitle>Drop us an Email</FormTitle>
      <FormDescription>
        We will get back to you in 1 working day.{" "}
      </FormDescription>
      <StaticImage
        src="../../../assets/images/BCA.png"
        alt="BCA"
        layout="fullWidth"

      />
      {/* <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <label>Full Name</label>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              {...register("name")}
            />
          </FormField>
          <FormField>
            <label>Phone</label>
            <input
              type="text"
              name="phone"
              placeholder="98888888"
              {...register("phone")}
            />
          </FormField>
          <FormField>
            <label>Email Address</label>
            <input
              type="email"
              name="name"
              placeholder="Email Address"
              {...register("email")}
            />
          </FormField>
          <FormField>
            <label>Your message</label>
            <textarea
              placeholder="Message"
              {...register("message")}
            ></textarea>
          </FormField>

          <FormField>
            <input
              type="submit"
              name="sbtn"
              value="Send Message"
            />
          </FormField>
        </form>
      </div> */}
    </FormContainer>
  )
}

export default ContactForm
